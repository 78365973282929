// extracted by mini-css-extract-plugin
export var animateCircle = "_404-module--animate-circle--vZOtX";
export var bounce = "_404-module--bounce--b0uDu";
export var content = "_404-module--content--fhGhn";
export var description = "_404-module--description--s-05-";
export var footer = "_404-module--footer--MMoSv";
export var ldsRing = "_404-module--lds-ring--18Rb6";
export var main = "_404-module--main--siWgq";
export var termsLinks = "_404-module--termsLinks--d9ZUb";
export var termsRules = "_404-module--termsRules--bRf63";
export var text = "_404-module--text--DpkFS";
export var title = "_404-module--title--AvL9D";