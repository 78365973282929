import * as React from 'react'
import { Link } from 'gatsby'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import { AppContext } from '../utils/context'
import Header from '../layouts/Header/Header'
import Sidebar from '../layouts/Sidebar/Sidebar'
import * as style from '../../assets/scss/pages/404.module.scss'
import ButtonPrimary from '../components/common/buttons/ButtonPrimary'

const NotFoundPage = ({ location }) => {
  const [modalState, setModalState] = useState(false)
  const [sidebarState, setSidebarState] = useState(false)
  const [blockScroll, setBlockScroll] = useState('')

  const sidebarToggle = (value) => {
    setSidebarState(value)
  }

  useEffect(() => {
    setBlockScroll(modalState ? 'sidebar-open' : '')
  }, [modalState])

  return (
    <div>
      <Helmet bodyAttributes={{ class: blockScroll }} />
      <AppContext.Provider value={[modalState, setModalState]}>
        <Header location={location} sidebarToggle={sidebarToggle} isDarkStyle />
        <Sidebar sidebarState={sidebarState} sidebarToggle={sidebarToggle} />
        <main className={style.main}>
          <div className="container">
            <div className={style.content}>
              <div className={style.text}>
                <h1 className={style.title}>
                  Oops, something unexpected happened!
                </h1>
                <p className={style.description}>
                  We searched high and low but couldn’t find what you’re looking
                  for. Let’s find a better place for you to go.
                </p>
                <Link to="/">
                  <ButtonPrimary>Go to Main Page</ButtonPrimary>
                </Link>
              </div>
              <div className={style.image}>
                <StaticImage src="../../static/images/404.png" alt="404" />
              </div>
            </div>
          </div>
          <footer className={style.footer}>
            <div className={style.termsLinks}>
              <Link to="/cookie-policy">Cookie Policy</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <p className={style.termsRules}>
              © {new Date().getFullYear()} Visartech Inc. All rights reserved.
              Registered in the U.S. Trademark Office
            </p>
          </footer>
        </main>
      </AppContext.Provider>
    </div>
  )
}

export default NotFoundPage
